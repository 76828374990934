import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Stacked_List_full_width_with_constrained_content from '../../components/application-ui/lists/stacked-lists/full_width_with_constrained_content';
import Stacked_List_full_width_with_links from '../../components/application-ui/lists/stacked-lists/full_width_with_links';
import Stacked_List_in_card_with_links from '../../components/application-ui/lists/stacked-lists/in_card_with_links';
import Stacked_List_narrow_with_actions from '../../components/application-ui/lists/stacked-lists/narrow_with_actions';
import Stacked_List_narrow_with_badges_on_dark from '../../components/application-ui/lists/stacked-lists/narrow_with_badges_on_dark';
import Stacked_List_narrow_with_small_avatars_on_dark from '../../components/application-ui/lists/stacked-lists/narrow_with_small_avatars_on_dark';
import Stacked_List_narrow_with_small_avatars from '../../components/application-ui/lists/stacked-lists/narrow_with_small_avatars';
import Stacked_List_narrow_with_sticky_headings from '../../components/application-ui/lists/stacked-lists/narrow_with_sticky_headings';
import Stacked_List_narrow_with_truncated_content from '../../components/application-ui/lists/stacked-lists/narrow_with_truncated_content';
import Stacked_List_narrow from '../../components/application-ui/lists/stacked-lists/narrow';
import Stacked_List_simple_on_dark from '../../components/application-ui/lists/stacked-lists/simple_on_dark';
import Stacked_List_simple from '../../components/application-ui/lists/stacked-lists/simple';
import Stacked_List_two_columns_with_links from '../../components/application-ui/lists/stacked-lists/two_columns_with_links';
import Stacked_List_with_badges__button__and_actions_menu from '../../components/application-ui/lists/stacked-lists/with_badges__button__and_actions_menu';
import Stacked_List_with_inline_links_and_actions_menu from '../../components/application-ui/lists/stacked-lists/with_inline_links_and_actions_menu';
import Stacked_List_with_inline_links_and_avatar_group from '../../components/application-ui/lists/stacked-lists/with_inline_links_and_avatar_group';
import Stacked_List_with_links from '../../components/application-ui/lists/stacked-lists/with_links';

function Stacked_Lists() {
  return (
    <div>
      <Stacked_List_full_width_with_constrained_content/>
      <Stacked_List_full_width_with_links/>
      <Stacked_List_in_card_with_links/>
      <Stacked_List_narrow_with_actions/>
      <Stacked_List_narrow_with_badges_on_dark/>
      <Stacked_List_narrow_with_small_avatars_on_dark/>
      <Stacked_List_narrow_with_small_avatars/>
      <Stacked_List_narrow_with_sticky_headings/>
      <Stacked_List_narrow_with_truncated_content/>
      <Stacked_List_narrow/>
      <Stacked_List_simple_on_dark/>
      <Stacked_List_simple/>
      <Stacked_List_two_columns_with_links/>
      <Stacked_List_with_badges__button__and_actions_menu/>
      <Stacked_List_with_inline_links_and_actions_menu/>
      <Stacked_List_with_inline_links_and_avatar_group/>
      <Stacked_List_with_links/>
    </div>
  );
}
export default Stacked_Lists;
