import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Toggle_short_toggle from '../../components/application-ui/forms/toggles/short_toggle';
import Toggle_simple_toggle from '../../components/application-ui/forms/toggles/simple_toggle';
import Toggle_toggle_with_icon from '../../components/application-ui/forms/toggles/toggle_with_icon';
import Toggle_with_left_label_and_description from '../../components/application-ui/forms/toggles/with_left_label_and_description';
import Toggle_with_right_label from '../../components/application-ui/forms/toggles/with_right_label';

function Toggles() {
  return (
    <div>
      <Toggle_short_toggle/>
      <Toggle_simple_toggle/>
      <Toggle_toggle_with_icon/>
      <Toggle_with_left_label_and_description/>
      <Toggle_with_right_label/>
    </div>
  );
}
export default Toggles;
