import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Review_avatars_with_separate_description from '../../components/ecommerce/components/reviews/avatars_with_separate_description';
import Review_multi_column from '../../components/ecommerce/components/reviews/multi_column';
import Review_simple_with_avatars from '../../components/ecommerce/components/reviews/simple_with_avatars';
import Review_with_summary_chart from '../../components/ecommerce/components/reviews/with_summary_chart';

function Reviews() {
  return (
    <div>
      <Review_avatars_with_separate_description/>
      <Review_multi_column/>
      <Review_simple_with_avatars/>
      <Review_with_summary_chart/>
    </div>
  );
}
export default Reviews;
