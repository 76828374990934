import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Empty_State_simple from '../../components/application-ui/feedback/empty-states/simple';
import Empty_State_with_dashed_border from '../../components/application-ui/feedback/empty-states/with_dashed_border';
import Empty_State_with_recommendations_grid from '../../components/application-ui/feedback/empty-states/with_recommendations_grid';
import Empty_State_with_recommendations from '../../components/application-ui/feedback/empty-states/with_recommendations';
import Empty_State_with_starting_points from '../../components/application-ui/feedback/empty-states/with_starting_points';
import Empty_State_with_templates from '../../components/application-ui/feedback/empty-states/with_templates';

function Empty_States() {
  return (
    <div>
      <Empty_State_simple/>
      <Empty_State_with_dashed_border/>
      <Empty_State_with_recommendations_grid/>
      <Empty_State_with_recommendations/>
      <Empty_State_with_starting_points/>
      <Empty_State_with_templates/>
    </div>
  );
}
export default Empty_States;
