import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Order_Summary_simple_with_full_order_details from '../../components/ecommerce/components/order-summaries/simple_with_full_order_details';
import Order_Summary_with_large_images_and_progress_bars from '../../components/ecommerce/components/order-summaries/with_large_images_and_progress_bars';
import Order_Summary_with_progress_bars from '../../components/ecommerce/components/order-summaries/with_progress_bars';
import Order_Summary_with_split_image from '../../components/ecommerce/components/order-summaries/with_split_image';

function Order_Summaries() {
  return (
    <div>
      <Order_Summary_simple_with_full_order_details/>
      <Order_Summary_with_large_images_and_progress_bars/>
      <Order_Summary_with_progress_bars/>
      <Order_Summary_with_split_image/>
    </div>
  );
}
export default Order_Summaries;
