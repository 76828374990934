import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Step_bullets_and_text from '../../components/application-ui/navigation/steps/bullets_and_text';
import Step_bullets from '../../components/application-ui/navigation/steps/bullets';
import Step_circles_with_text from '../../components/application-ui/navigation/steps/circles_with_text';
import Step_circles from '../../components/application-ui/navigation/steps/circles';
import Step_panels_with_border from '../../components/application-ui/navigation/steps/panels_with_border';
import Step_panels from '../../components/application-ui/navigation/steps/panels';
import Step_progress_bar from '../../components/application-ui/navigation/steps/progress_bar';
import Step_simple from '../../components/application-ui/navigation/steps/simple';

function Steps() {
  return (
    <div>
      <Step_bullets_and_text/>
      <Step_bullets/>
      <Step_circles_with_text/>
      <Step_circles/>
      <Step_panels_with_border/>
      <Step_panels/>
      <Step_progress_bar/>
      <Step_simple/>
    </div>
  );
}
export default Steps;
