import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Shopping_Cart_modal from '../../components/ecommerce/components/shopping-carts/modal';
import Shopping_Cart_popover from '../../components/ecommerce/components/shopping-carts/popover';
import Shopping_Cart_single_column from '../../components/ecommerce/components/shopping-carts/single_column';
import Shopping_Cart_slide_over from '../../components/ecommerce/components/shopping-carts/slide_over';
import Shopping_Cart_two_column_with_quantity_dropdown from '../../components/ecommerce/components/shopping-carts/two_column_with_quantity_dropdown';
import Shopping_Cart_with_extended_summary from '../../components/ecommerce/components/shopping-carts/with_extended_summary';

function Shopping_Carts() {
  return (
    <div>
      <Shopping_Cart_modal/>
      <Shopping_Cart_popover/>
      <Shopping_Cart_single_column/>
      <Shopping_Cart_slide_over/>
      <Shopping_Cart_two_column_with_quantity_dropdown/>
      <Shopping_Cart_with_extended_summary/>
    </div>
  );
}
export default Shopping_Carts;
