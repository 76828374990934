import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Avatar_avatar_group_stacked_bottom_to_top from '../../components/application-ui/elements/avatars/avatar_group_stacked_bottom_to_top';
import Avatar_avatar_group_stacked_top_to_bottom from '../../components/application-ui/elements/avatars/avatar_group_stacked_top_to_bottom';
import Avatar_circular_avatars_with_bottom_notification from '../../components/application-ui/elements/avatars/circular_avatars_with_bottom_notification';
import Avatar_circular_avatars_with_placeholder_icon from '../../components/application-ui/elements/avatars/circular_avatars_with_placeholder_icon';
import Avatar_circular_avatars_with_placeholder_initials from '../../components/application-ui/elements/avatars/circular_avatars_with_placeholder_initials';
import Avatar_circular_avatars_with_top_notification from '../../components/application-ui/elements/avatars/circular_avatars_with_top_notification';
import Avatar_circular_avatars from '../../components/application-ui/elements/avatars/circular_avatars';
import Avatar_rounded_avatars_with_bottom_notification from '../../components/application-ui/elements/avatars/rounded_avatars_with_bottom_notification';
import Avatar_rounded_avatars_with_top_notification from '../../components/application-ui/elements/avatars/rounded_avatars_with_top_notification';
import Avatar_rounded_avatars from '../../components/application-ui/elements/avatars/rounded_avatars';
import Avatar_with_text from '../../components/application-ui/elements/avatars/with_text';

function Avatars() {
  return (
    <div>
        <Avatar_avatar_group_stacked_bottom_to_top/>
        <Avatar_avatar_group_stacked_top_to_bottom/>
        <Avatar_circular_avatars_with_bottom_notification/>
        <Avatar_circular_avatars_with_placeholder_icon/>
        <Avatar_circular_avatars_with_placeholder_initials/>
        <Avatar_circular_avatars_with_top_notification/>
        <Avatar_circular_avatars/>
        <Avatar_rounded_avatars_with_bottom_notification/>
        <Avatar_rounded_avatars_with_top_notification/>
        <Avatar_rounded_avatars/>
        <Avatar_with_text/>
    </div>
  );
}
export default Avatars;
