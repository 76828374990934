import './App.css';
import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Nav_dark_with_quick_action from './components/application-ui/navigation/navbars/dark_with_quick_action';
import Nav_dark_with_search from './components/application-ui/navigation/navbars/dark_with_search';
import Nav_simple_dark_with_menu_button_on_left from './components/application-ui/navigation/navbars/simple_dark_with_menu_button_on_left';
import Nav_simple_dark from './components/application-ui/navigation/navbars/simple_dark';
import Nav_simple_with_menu_button_on_left from './components/application-ui/navigation/navbars/simple_with_menu_button_on_left';
import Nav_simple from './components/application-ui/navigation/navbars/simple';
import Nav_with_centered_search_and_secondary_links_dark from './components/application-ui/navigation/navbars/with_centered_search_and_secondary_links_dark';
import Nav_with_centered_search_and_secondary_links from './components/application-ui/navigation/navbars/with_centered_search_and_secondary_links';
import Nav_with_quick_action from './components/application-ui/navigation/navbars/with_quick_action';
import Nav_with_search_in_column_layout from './components/application-ui/navigation/navbars/with_search_in_column_layout';
import Nav_with_search from './components/application-ui/navigation/navbars/with_search';

import Side_brand from './components/application-ui/navigation/sidebar-navigation/brand';
import Side_dark from './components/application-ui/navigation/sidebar-navigation/dark';

import Modal_centered_with_single_action from './components/application-ui/overlays/modals/centered_with_single_action';
import Modal_centered_with_wide_buttons from './components/application-ui/overlays/modals/centered_with_wide_buttons';
import Modal_simple_alert from './components/application-ui/overlays/modals/simple_alert';
import Modal_simple_alert_with_left_aligned_buttons from './components/application-ui/overlays/modals/simple_alert_with_left_aligned_buttons';
import Modal_simple_with_dismiss_button from './components/application-ui/overlays/modals/simple_with_dismiss_button';
import Modal_simple_with_gray_footer from './components/application-ui/overlays/modals/simple_with_gray_footer';

import Notification_condensed from './components/application-ui/overlays/notifications/condensed';
import Notification_simple from './components/application-ui/overlays/notifications/simple';
import Notification_with_actions_below from './components/application-ui/overlays/notifications/with_actions_below';
import Notification_with_avatar from './components/application-ui/overlays/notifications/with_avatar';
import Notification_with_buttons_below from './components/application-ui/overlays/notifications/with_buttons_below';
import Notification_with_split_buttons from './components/application-ui/overlays/notifications/with_split_buttons';

import Sideover_contact_list_example from './components/application-ui/overlays/slide-overs/contact_list_example';
import Sideover_create_project_form_example from './components/application-ui/overlays/slide-overs/create_project_form_example';
import Sideover_empty from './components/application-ui/overlays/slide-overs/empty';
import Sideover_file_details_example from './components/application-ui/overlays/slide-overs/file_details_example';
import Sideover_user_profile_example from './components/application-ui/overlays/slide-overs/user_profile_example';
import Sideover_wide_create_project_form_example from './components/application-ui/overlays/slide-overs/wide_create_project_form_example';
import Sideover_wide_empty from './components/application-ui/overlays/slide-overs/wide_empty';
import Sideover_wide_horizontal_user_profile_example from './components/application-ui/overlays/slide-overs/wide_horizontal_user_profile_example';
import Sideover_with_background_overlay from './components/application-ui/overlays/slide-overs/with_background_overlay';
import Sideover_with_branded_header from './components/application-ui/overlays/slide-overs/with_branded_header';
import Sideover_with_close_button_on_outside from './components/application-ui/overlays/slide-overs/with_close_button_on_outside';
import Sideover_with_sticky_footer from './components/application-ui/overlays/slide-overs/with_sticky_footer';

import Vertical_on_gray from './components/application-ui/navigation/vertical-navigation/on_gray';
import Vertical_simple from './components/application-ui/navigation/vertical-navigation/simple';
import Vertical_with_badges from './components/application-ui/navigation/vertical-navigation/with_badges';
import Vertical_with_icons_and_badges from './components/application-ui/navigation/vertical-navigation/with_icons_and_badges';
import Vertical_with_icons from './components/application-ui/navigation/vertical-navigation/with_icons';
import Vertical_with_secondary_navigation from './components/application-ui/navigation/vertical-navigation/with_secondary_navigation';

import Command_dark_with_icons from './components/application-ui/navigation/command-palettes/dark_with_icons';
import Command_semi_transparent_with_icons from './components/application-ui/navigation/command-palettes/semi_transparent_with_icons';
import Command_simple_with_padding from './components/application-ui/navigation/command-palettes/simple_with_padding';
import Command_simple from './components/application-ui/navigation/command-palettes/simple';
import Command_with_footer from './components/application-ui/navigation/command-palettes/with_footer';
import Command_with_groups from './components/application-ui/navigation/command-palettes/with_groups';
import Command_with_icons from './components/application-ui/navigation/command-palettes/with_icons';
import Command_with_images_and_descriptions from './components/application-ui/navigation/command-palettes/with_images_and_descriptions';
import Command_with_preview from './components/application-ui/navigation/command-palettes/with_preview';

function Demo1() {
  return (
    <div>
      <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
          <Side_dark/>
      </div>
      <div className="xl:pl-72">
        <Nav_with_search_in_column_layout/>
        <Outlet />
      </div>
    </div>
  );
}
export default Demo1;
