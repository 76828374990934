import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Alert_with_accent_border from '../../components/application-ui/feedback/alerts/with_accent_border';
import Alert_with_actions from '../../components/application-ui/feedback/alerts/with_actions';
import Alert_with_description from '../../components/application-ui/feedback/alerts/with_description';
import Alert_with_dismiss_button from '../../components/application-ui/feedback/alerts/with_dismiss_button';
import Alert_with_link_on_right from '../../components/application-ui/feedback/alerts/with_link_on_right';
import Alert_with_list from '../../components/application-ui/feedback/alerts/with_list';

function Alerts() {
  return (
    <div>
      <Alert_with_accent_border/>
      <Alert_with_actions/>
      <Alert_with_description/>
      <Alert_with_dismiss_button/>
      <Alert_with_link_on_right/>
      <Alert_with_list/>
    </div>
  );
}
export default Alerts;
