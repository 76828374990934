import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import SignIn_Form_simple_card from '../../components/application-ui/forms/sign-in-forms/simple_card';
import SignIn_Form_simple_no_labels from '../../components/application-ui/forms/sign-in-forms/simple_no_labels';
import SingIn_Form_simple_on_dark from '../../components/application-ui/forms/sign-in-forms/simple_on_dark';
import SignIn_Form_simple from '../../components/application-ui/forms/sign-in-forms/simple';
import SingIn_Form_split_screen from '../../components/application-ui/forms/sign-in-forms/split_screen';

function SignIn_Forms() {
  return (
    <div>
      <SignIn_Form_simple_card/>
      <SignIn_Form_simple_no_labels/>
      <SingIn_Form_simple_on_dark/>
      <SignIn_Form_simple/>
      <SingIn_Form_split_screen/>
    </div>
  );
}
export default SignIn_Forms;
