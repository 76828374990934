import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Input_Group_input_with_add_on from '../../components/application-ui/forms/input-groups/input_with_add_on';
import Input_Group_input_with_corner_hint from '../../components/application-ui/forms/input-groups/input_with_corner_hint';
import Input_Group_input_with_disabled_state from '../../components/application-ui/forms/input-groups/input_with_disabled_state';
import Input_Group_input_with_gray_background_and_bottom_border from '../../components/application-ui/forms/input-groups/input_with_gray_background_and_bottom_border';
import Input_Group_input_with_hidden_label from '../../components/application-ui/forms/input-groups/input_with_hidden_label';
import Input_Group_input_with_inline_add_on from '../../components/application-ui/forms/input-groups/input_with_inline_add_on';
import Input_Group_input_with_inline_leading_add_on_and_trailing_dropdown from '../../components/application-ui/forms/input-groups/input_with_inline_leading_add_on_and_trailing_dropdown';
import Input_Group_input_with_inline_leading_and_trailing_add_ons from '../../components/application-ui/forms/input-groups/input_with_inline_leading_and_trailing_add_ons';
import Input_Group_input_with_inline_leading_dropdown from '../../components/application-ui/forms/input-groups/input_with_inline_leading_dropdown';
import Input_Group_input_with_inset_label from '../../components/application-ui/forms/input-groups/input_with_inset_label';
import Input_Group_input_with_keyboard_shortcut from '../../components/application-ui/forms/input-groups/input_with_keyboard_shortcut';
import Input_Group_input_with_label_and_help_text from '../../components/application-ui/forms/input-groups/input_with_label_and_help_text';
import Input_Group_input_with_label from '../../components/application-ui/forms/input-groups/input_with_label';
import Input_Group_input_with_leading_icon_and_trailing_button from '../../components/application-ui/forms/input-groups/input_with_leading_icon_and_trailing_button';
import Input_Group_input_with_leading_icon from '../../components/application-ui/forms/input-groups/input_with_leading_icon';
import Input_Group_input_with_overlapping_label from '../../components/application-ui/forms/input-groups/input_with_overlapping_label';
import Input_Group_input_with_pill_shape from '../../components/application-ui/forms/input-groups/input_with_pill_shape';
import Input_Group_input_with_trailing_icon from '../../components/application-ui/forms/input-groups/input_with_trailing_icon';
import Input_Group_input_with_validation_error from '../../components/application-ui/forms/input-groups/input_with_validation_error';
import Input_Group_inputs_with_inset_labels_and_shared_borders from '../../components/application-ui/forms/input-groups/inputs_with_inset_labels_and_shared_borders';
import Input_Group_inputs_with_shared_borders from '../../components/application-ui/forms/input-groups/inputs_with_shared_borders';

function Input_Groups() {
  return (
    <div>
        <Input_Group_input_with_add_on/>
        <Input_Group_input_with_corner_hint/>
        <Input_Group_input_with_disabled_state/>
        <Input_Group_input_with_gray_background_and_bottom_border/>
        <Input_Group_input_with_hidden_label/>
        <Input_Group_input_with_inline_add_on/>
        <Input_Group_input_with_inline_leading_add_on_and_trailing_dropdown/>
        <Input_Group_input_with_inline_leading_and_trailing_add_ons/>
        <Input_Group_input_with_inline_leading_dropdown/>
        <Input_Group_input_with_inset_label/>
        <Input_Group_input_with_keyboard_shortcut/>
        <Input_Group_input_with_label_and_help_text/>
        <Input_Group_input_with_label/>
        <Input_Group_input_with_leading_icon_and_trailing_button/>
        <Input_Group_input_with_leading_icon/>
        <Input_Group_input_with_overlapping_label/>
        <Input_Group_input_with_pill_shape/>
        <Input_Group_input_with_trailing_icon/>
        <Input_Group_input_with_validation_error/>
        <Input_Group_inputs_with_inset_labels_and_shared_borders/>
        <Input_Group_inputs_with_shared_borders/>
    </div>
  );
}
export default Input_Groups;
