import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Button_Group_basic from '../../components/application-ui/elements/button-groups/basic';
import Container_constrained_to_breakpoint_with_padded_content from '../../components/application-ui/layout/containers/constrained_to_breakpoint_with_padded_content';
import Container_constrained_with_padded_content from '../../components/application-ui/layout/containers/constrained_with_padded_content';
import Container_full_width_on_mobile__constrained_to_breakpoint_with_padded_content_above_mobile from '../../components/application-ui/layout/containers/full_width_on_mobile__constrained_to_breakpoint_with_padded_content_above_mobile';
import Container_full_width_on_mobile__constrained_with_padded_content_above from '../../components/application-ui/layout/containers/full_width_on_mobile__constrained_with_padded_content_above';
import Container_narrow_constrained_with_padded_content from '../../components/application-ui/layout/containers/narrow_constrained_with_padded_content';

function Containers() {
  return (
    <div>
      <Container_constrained_to_breakpoint_with_padded_content><Button_Group_basic/></Container_constrained_to_breakpoint_with_padded_content>
      <Container_constrained_with_padded_content><Button_Group_basic/></Container_constrained_with_padded_content>
      <Container_full_width_on_mobile__constrained_to_breakpoint_with_padded_content_above_mobile><Button_Group_basic/></Container_full_width_on_mobile__constrained_to_breakpoint_with_padded_content_above_mobile>
      <Container_full_width_on_mobile__constrained_with_padded_content_above><Button_Group_basic/></Container_full_width_on_mobile__constrained_with_padded_content_above>
      <Container_narrow_constrained_with_padded_content><Button_Group_basic/></Container_narrow_constrained_with_padded_content>
    </div>
  );
}
export default Containers;
