import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Product_Overview_split_with_image from '../../components/ecommerce/components/product-overviews/split_with_image';
import Product_Overview_with_image_gallery_and_expandable_details from '../../components/ecommerce/components/product-overviews/with_image_gallery_and_expandable_details';
import Product_Overview_with_image_grid from '../../components/ecommerce/components/product-overviews/with_image_grid';
import Product_Overview_with_tabs from '../../components/ecommerce/components/product-overviews/with_tabs';
import Product_Overview_with_tiered_images from '../../components/ecommerce/components/product-overviews/with_tiered_images';

function Product_Overviews() {
  return (
    <div>
        <Product_Overview_split_with_image/>
        <Product_Overview_with_image_gallery_and_expandable_details/>
        <Product_Overview_with_image_grid/>
        <Product_Overview_with_tabs/>
        <Product_Overview_with_tiered_images/>
    </div>
  );
}
export default Product_Overviews;
