import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Product_Quickview_with_color_and_size_selector from '../../components/ecommerce/components/product-quickviews/with_color_and_size_selector';
import Product_Quickview_with_color_selector__size_selector__and_details_link from '../../components/ecommerce/components/product-quickviews/with_color_selector__size_selector__and_details_link';
import Product_Quickview_with_color_selector_and_description from '../../components/ecommerce/components/product-quickviews/with_color_selector_and_description';
import Product_Quickview_with_large_size_selector from '../../components/ecommerce/components/product-quickviews/with_large_size_selector';

function Product_Quickviews() {
  return (
    <div>
        <Product_Quickview_with_color_and_size_selector/>
        <Product_Quickview_with_color_selector__size_selector__and_details_link/>
        <Product_Quickview_with_color_selector_and_description/>
        <Product_Quickview_with_large_size_selector/>
    </div>
  );
}
export default Product_Quickviews;
