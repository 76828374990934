import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Page_Heading_card_with_avatar_and_stats from '../../components/application-ui/headings/page-headings/card_with_avatar_and_stats';
import Page_Heading_with_actions_and_breadcrumbs_on_dark from '../../components/application-ui/headings/page-headings/with_actions_and_breadcrumbs_on_dark';
import Page_Heading_with_actions_and_breadcrumbs from '../../components/application-ui/headings/page-headings/with_actions_and_breadcrumbs';
import Page_Heading_with_actions_on_dark from '../../components/application-ui/headings/page-headings/with_actions_on_dark';
import Page_Heading_with_actions from '../../components/application-ui/headings/page-headings/with_actions';
import Page_Heading_with_avatar_and_actions from '../../components/application-ui/headings/page-headings/with_avatar_and_actions';
import Page_Heading_with_banner_image from '../../components/application-ui/headings/page-headings/with_banner_image';
import Page_Heading_with_filters_and_action from '../../components/application-ui/headings/page-headings/with_filters_and_action';
import Page_Heading_with_logo__meta_and_actions from '../../components/application-ui/headings/page-headings/with_logo__meta_and_actions';
import Page_Heading_with_meta__actions__and_breadcrumbs_on_dark from '../../components/application-ui/headings/page-headings/with_meta__actions__and_breadcrumbs_on_dark';
import Page_Heading_with_meta__actions__and_breadcrumbs from '../../components/application-ui/headings/page-headings/with_meta__actions__and_breadcrumbs';
import Page_Heading_with_meta_and_actions_on_dark from '../../components/application-ui/headings/page-headings/with_meta_and_actions_on_dark';
import Page_Heading_with_meta_and_actions from '../../components/application-ui/headings/page-headings/with_meta_and_actions';

function Page_Headings() {
  return (
    <div>
      <Page_Heading_card_with_avatar_and_stats/>
      <Page_Heading_with_actions_and_breadcrumbs_on_dark/>
      <Page_Heading_with_actions_and_breadcrumbs/>
      <Page_Heading_with_actions_on_dark/>
      <Page_Heading_with_actions/>
      <Page_Heading_with_avatar_and_actions/>
      <Page_Heading_with_banner_image/>
      <Page_Heading_with_filters_and_action/>
      <Page_Heading_with_logo__meta_and_actions/>
      <Page_Heading_with_meta__actions__and_breadcrumbs_on_dark/>
      <Page_Heading_with_meta__actions__and_breadcrumbs/>
      <Page_Heading_with_meta_and_actions_on_dark/>
      <Page_Heading_with_meta_and_actions/>
    </div>
  );
}
export default Page_Headings;
