import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Card_Heading_simple from '../../components/application-ui/headings/card-headings/simple';
import Card_Heading_with_action from '../../components/application-ui/headings/card-headings/with_action';
import Card_Heading_with_avatar__meta__and_dropdown from '../../components/application-ui/headings/card-headings/with_avatar__meta__and_dropdown';
import Card_Heading_with_avatar_and_actions from '../../components/application-ui/headings/card-headings/with_avatar_and_actions';
import Card_Heading_with_description from '../../components/application-ui/headings/card-headings/with_description';
import Card_Heading_with_description_and_action from '../../components/application-ui/headings/card-headings/with_description_and_action';

function Card_Headings() {
  return (
    <div>
      <Card_Heading_simple/>
      <Card_Heading_with_action/>
      <Card_Heading_with_avatar__meta__and_dropdown/>
      <Card_Heading_with_avatar_and_actions/>
      <Card_Heading_with_description/>
      <Card_Heading_with_description_and_action/>
    </div>
  );
}
export default Card_Headings;
