import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Radio_Group_cards from '../../components/application-ui/forms/radio-groups/cards';
import Radio_Group_color_picker from '../../components/application-ui/forms/radio-groups/color_picker';
import Radio_Group_list_with_description from '../../components/application-ui/forms/radio-groups/list_with_description';
import Radio_Group_list_with_descriptions_in_panel from '../../components/application-ui/forms/radio-groups/list_with_descriptions_in_panel';
import Radio_Group_list_with_inline_description from '../../components/application-ui/forms/radio-groups/list_with_inline_description';
import Radio_Group_list_with_radio_on_right from '../../components/application-ui/forms/radio-groups/list_with_radio_on_right';
import Radio_Group_simple_inline_list from '../../components/application-ui/forms/radio-groups/simple_inline_list';
import Radio_Group_simple_list_with_radio_on_right from '../../components/application-ui/forms/radio-groups/simple_list_with_radio_on_right';
import Radio_Group_simple_list from '../../components/application-ui/forms/radio-groups/simple_list';
import Radio_Group_simple_table from '../../components/application-ui/forms/radio-groups/simple_table';
import Radio_Group_small_cards from '../../components/application-ui/forms/radio-groups/small_cards';
import Radio_Group_stacked_cards from '../../components/application-ui/forms/radio-groups/stacked_cards';

function Radio_Groups() {
  return (
    <div>
      <Radio_Group_cards/>
      <Radio_Group_color_picker/>
      <Radio_Group_list_with_description/>
      <Radio_Group_list_with_descriptions_in_panel/>
      <Radio_Group_list_with_inline_description/>
      <Radio_Group_list_with_radio_on_right/>
      <Radio_Group_simple_inline_list/>
      <Radio_Group_simple_list_with_radio_on_right/>
      <Radio_Group_simple_list/>
      <Radio_Group_simple_table/>
      <Radio_Group_small_cards/>
      <Radio_Group_stacked_cards/>
    </div>
  );
}
export default Radio_Groups;
