import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Category_Preview_three_column_with_description from '../../components/ecommerce/components/category-previews/three_column_with_description';
import Category_Preview_three_column from '../../components/ecommerce/components/category-previews/three_column';
import Category_Preview_with_background_image_and_detail_overlay from '../../components/ecommerce/components/category-previews/with_background_image_and_detail_overlay';
import Category_Preview_with_image_backgrounds from '../../components/ecommerce/components/category-previews/with_image_backgrounds';
import Category_Preview_with_scrolling_cards from '../../components/ecommerce/components/category-previews/with_scrolling_cards';

function Category_Previews() {
  return (
    <div>
        <Category_Preview_three_column_with_description/>
        <Category_Preview_three_column/>
        <Category_Preview_with_background_image_and_detail_overlay/>
        <Category_Preview_with_image_backgrounds/>
        <Category_Preview_with_scrolling_cards/>
    </div>
  );
}
export default Category_Previews;
