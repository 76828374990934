import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Product_List_card_with_full_details from '../../components/ecommerce/components/product-lists/card_with_full_details';
import Product_List_simple from '../../components/ecommerce/components/product-lists/simple';
import Product_List_with_border_grid from '../../components/ecommerce/components/product-lists/with_border_grid';
import Product_List_with_color_swatches_and_horizontal_scrolling from '../../components/ecommerce/components/product-lists/with_color_swatches_and_horizontal_scrolling';
import Product_List_with_cta_link from '../../components/ecommerce/components/product-lists/with_cta_link';
import Product_List_with_image_overlay_and_add_button from '../../components/ecommerce/components/product-lists/with_image_overlay_and_add_button';
import Product_List_with_inline_price from '../../components/ecommerce/components/product-lists/with_inline_price';
import Product_List_with_inline_price_and_cta_link from '../../components/ecommerce/components/product-lists/with_inline_price_and_cta_link';
import Product_List_with_supporting_text from '../../components/ecommerce/components/product-lists/with_supporting_text';
import Product_List_with_tall_images from '../../components/ecommerce/components/product-lists/with_tall_images';
import Product_List_with_tall_images_and_cta_link from '../../components/ecommerce/components/product-lists/with_tall_images_and_cta_link';

function Product_Lists() {
  return (
    <div>
        <Product_List_card_with_full_details/>
        <Product_List_simple/>
        <Product_List_with_border_grid/>
        <Product_List_with_color_swatches_and_horizontal_scrolling/>
        <Product_List_with_cta_link/>
        <Product_List_with_image_overlay_and_add_button/>
        <Product_List_with_inline_price/>
        <Product_List_with_inline_price_and_cta_link/>
        <Product_List_with_supporting_text/>
        <Product_List_with_tall_images/>
        <Product_List_with_tall_images_and_cta_link/>
        
    </div>
  );
}
export default Product_Lists;
