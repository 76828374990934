import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Action_Panel_simple_well from '../../components/application-ui/forms/action-panels/simple_well';
import Action_Panel_simple from '../../components/application-ui/forms/action-panels/simple';
import Action_Panel_with_button_at_top_right from '../../components/application-ui/forms/action-panels/with_button_at_top_right';
import Action_Panel_with_button_on_right from '../../components/application-ui/forms/action-panels/with_button_on_right';
import Action_Panel_with_input from '../../components/application-ui/forms/action-panels/with_input';
import Action_Panel_with_link from '../../components/application-ui/forms/action-panels/with_link';
import Action_Panel_with_toggle from '../../components/application-ui/forms/action-panels/with_toggle';
import Action_Panel_with_well from '../../components/application-ui/forms/action-panels/with_well';

function Action_Panels() {
  return (
    <div>
      <Action_Panel_simple_well/>
      <Action_Panel_simple/>
      <Action_Panel_with_button_at_top_right/>
      <Action_Panel_with_button_on_right/>
      <Action_Panel_with_input/>
      <Action_Panel_with_link/>
      <Action_Panel_with_toggle/>
      <Action_Panel_with_well/>
    </div>
  );
}
export default Action_Panels;
