import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Store_Navigation_with_centered_logo_and_featured_categories from '../../components/ecommerce/components/store-navigation/with_centered_logo_and_featured_categories';
import Store_Navigation_with_double_column_and_persistent_mobile_nav from '../../components/ecommerce/components/store-navigation/with_double_column_and_persistent_mobile_nav';
import Store_Navigation_with_featured_categories from '../../components/ecommerce/components/store-navigation/with_featured_categories';
import Store_Navigation_with_image_grid from '../../components/ecommerce/components/store-navigation/with_image_grid';
import Store_Navigation_with_simple_menu_and_promo from '../../components/ecommerce/components/store-navigation/with_simple_menu_and_promo';


function Store_Navigations() {
  return (
    <div>
        <Store_Navigation_with_centered_logo_and_featured_categories/>
        <Outlet />
    </div>
  );
}
export default Store_Navigations;
