import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Select_Menu_branded_with_supported_text from '../../components/application-ui/forms/select-menus/branded_with_supported_text';
import Select_Menu_custom_with_avatar from '../../components/application-ui/forms/select-menus/custom_with_avatar';
import Select_Menu_custom_with_check_on_left from '../../components/application-ui/forms/select-menus/custom_with_check_on_left';
import Select_Menu_custom_with_status_indicator from '../../components/application-ui/forms/select-menus/custom_with_status_indicator';
import Select_Menu_simple_custom from '../../components/application-ui/forms/select-menus/simple_custom';
import Select_Menu_simple_native from '../../components/application-ui/forms/select-menus/simple_native';
import Select_Menu_with_secondary_text from '../../components/application-ui/forms/select-menus/with_secondary_text';

function Select_Menus() {
  return (
    <div>
      <Select_Menu_branded_with_supported_text/>
      <Select_Menu_custom_with_avatar/>
      <Select_Menu_custom_with_check_on_left/>
      <Select_Menu_custom_with_status_indicator/>
      <Select_Menu_simple_custom/>
      <Select_Menu_simple_native/>
      <Select_Menu_with_secondary_text/>
    </div>
  );
}
export default Select_Menus;
