import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Button_Group_basic from '../../components/application-ui/elements/button-groups/basic';
import List_Container_card_with_dividers__full_width_on_mobile from '../../components/application-ui/layout/list-containers/card_with_dividers__full_width_on_mobile';
import List_Container_card_with_dividers from '../../components/application-ui/layout/list-containers/card_with_dividers';
import List_Container_flat_card_with_dividers from '../../components/application-ui/layout/list-containers/flat_card_with_dividers';
import List_Container_separate_cards__full_width_on_mobile from '../../components/application-ui/layout/list-containers/separate_cards__full_width_on_mobile';
import List_Container_separate_cards from '../../components/application-ui/layout/list-containers/separate_cards';
import List_Container_simple_with_dividers__full_width_on_mobile from '../../components/application-ui/layout/list-containers/simple_with_dividers__full_width_on_mobile';
import List_Container_simple_with_dividers from '../../components/application-ui/layout/list-containers/simple_with_dividers';

function List_Containers() {
  return (
    <div>
      <List_Container_card_with_dividers__full_width_on_mobile content={<Button_Group_basic/>}/>
      <br/>
      <br/>
      <List_Container_card_with_dividers content={<Button_Group_basic/>}/>
      <br/>
      <br/>
      <List_Container_flat_card_with_dividers content={<Button_Group_basic/>}/>
      <br/>
      <br/>
      <List_Container_separate_cards__full_width_on_mobile content={<Button_Group_basic/>}/>
      <br/>
      <br/>
      <List_Container_separate_cards content={<Button_Group_basic/>}/>
      <br/>
      <br/>
      <List_Container_simple_with_dividers__full_width_on_mobile content={<Button_Group_basic/>}/>
      <br/>
      <br/>
      <List_Container_simple_with_dividers content={<Button_Group_basic/>}/>
    </div>
  );
}
export default List_Containers;
