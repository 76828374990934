import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Promo_Section_full_width_with_background_image from '../../components/ecommerce/components/promo-sections/full_width_with_background_image';
import Promo_Section_full_width_with_background_image_and_large_content from '../../components/ecommerce/components/promo-sections/full_width_with_background_image_and_large_content';
import Promo_Section_full_width_with_overlapping_image_tiles from '../../components/ecommerce/components/promo-sections/full_width_with_overlapping_image_tiles';
import Promo_Section_with_background_image from '../../components/ecommerce/components/promo-sections/with_background_image';
import Promo_Section_with_fading_background_image_and_testimonials from '../../components/ecommerce/components/promo-sections/with_fading_background_image_and_testimonials';
import Promo_Section_with_image_tiles from '../../components/ecommerce/components/promo-sections/with_image_tiles';
import Promo_Section_with_offers_and_split_image from '../../components/ecommerce/components/promo-sections/with_offers_and_split_image';
import Promo_Section_with_overlapping_image_tiles from '../../components/ecommerce/components/promo-sections/with_overlapping_image_tiles';

function Promo_Sections() {
  return (
    <div>
      <Promo_Section_full_width_with_background_image/>
      <Promo_Section_full_width_with_background_image_and_large_content/>
      <Promo_Section_full_width_with_overlapping_image_tiles/>
      <Promo_Section_with_background_image/>
      <Promo_Section_with_fading_background_image_and_testimonials/>
      <Promo_Section_with_image_tiles/>
      <Promo_Section_with_offers_and_split_image/>
      <Promo_Section_with_overlapping_image_tiles/>
    </div>
  );
}
export default Promo_Sections;
