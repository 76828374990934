import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Incentive_2x2_grid_with_illustrations from '../../components/ecommerce/components/incentives/2x2_grid_with_illustrations';
import Incentive_3_column_with_icons from '../../components/ecommerce/components/incentives/3_column_with_icons';
import Incentive_3_column_with_icons_and_supporting_text from '../../components/ecommerce/components/incentives/3_column_with_icons_and_supporting_text';
import Incentive_3_column_with_illustrations_and_centered_text from '../../components/ecommerce/components/incentives/3_column_with_illustrations_and_centered_text';
import Incentive_3_column_with_illustrations_and_header from '../../components/ecommerce/components/incentives/3_column_with_illustrations_and_header';
import Incentive_3_column_with_illustrations_and_heading from '../../components/ecommerce/components/incentives/3_column_with_illustrations_and_heading';
import Incentive_3_column_with_illustrations_and_split_header from '../../components/ecommerce/components/incentives/3_column_with_illustrations_and_split_header';
import Incentive_4_column_with_illustrations from '../../components/ecommerce/components/incentives/4_column_with_illustrations';

function Incentives() {
  return (
    <div>
      <Incentive_2x2_grid_with_illustrations/>
      <Incentive_3_column_with_icons/>
      <Incentive_3_column_with_icons_and_supporting_text/>
      <Incentive_3_column_with_illustrations_and_centered_text/>
      <Incentive_3_column_with_illustrations_and_header/>
      <Incentive_3_column_with_illustrations_and_heading/>
      <Incentive_3_column_with_illustrations_and_split_header/>
      <Incentive_4_column_with_illustrations/>
    </div>
  );
}
export default Incentives;
