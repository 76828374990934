import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Product_Feature_with_alternating_sections from '../../components/ecommerce/components/product-features/with_alternating_sections';
import Product_Feature_with_fading_image from '../../components/ecommerce/components/product-features/with_fading_image';
import Product_Feature_with_header__images__and_descriptions from '../../components/ecommerce/components/product-features/with_header__images__and_descriptions';
import Product_Feature_with_image_grid from '../../components/ecommerce/components/product-features/with_image_grid';
import Product_Feature_with_split_image from '../../components/ecommerce/components/product-features/with_split_image';
import Product_Feature_with_square_images from '../../components/ecommerce/components/product-features/with_square_images';
import Product_Feature_with_tabs from '../../components/ecommerce/components/product-features/with_tabs';
import Product_Feature_with_tiered_images from '../../components/ecommerce/components/product-features/with_tiered_images';
import Product_Feature_with_wide_images from '../../components/ecommerce/components/product-features/with_wide_images';
import Product_with_featured_details from '../../components/ecommerce/page-examples/product-pages/with_featured_details';

function Product_Features() {
  return (
    <div>
        <Product_Feature_with_alternating_sections/>
        <Product_Feature_with_fading_image/>
        <Product_Feature_with_header__images__and_descriptions/>
        <Product_Feature_with_image_grid/>
        <Product_Feature_with_split_image/>
        <Product_Feature_with_square_images/>
        <Product_Feature_with_tabs/>
        <Product_Feature_with_tiered_images/>
        <Product_Feature_with_wide_images/>
        <Product_with_featured_details/>
    </div>
  );
}
export default Product_Features;
