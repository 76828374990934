import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Dropdown_simple from '../../components/application-ui/elements/dropdowns/simple';
import Dropdown_with_dividers from '../../components/application-ui/elements/dropdowns/with_dividers';
import Dropdown_with_icons from '../../components/application-ui/elements/dropdowns/with_icons';
import Dropdown_with_minimal_menu_icon from '../../components/application-ui/elements/dropdowns/with_minimal_menu_icon';
import Dropdown_with_simple_header from '../../components/application-ui/elements/dropdowns/with_simple_header';

function Dropdowns() {
  return (
    <div className='pl-20'>
      <Dropdown_simple/>
      <Dropdown_with_dividers/>
      <Dropdown_with_icons/>
      <Dropdown_with_minimal_menu_icon/>
      <Dropdown_with_simple_header/>
    </div>
  );
}
export default Dropdowns;
