import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Checkbox_list_with_checkbox_on_right from '../../components/application-ui/forms/checkboxes/list_with_checkbox_on_right';
import Checkbox_list_with_description from '../../components/application-ui/forms/checkboxes/list_with_description';
import Checkbox_list_with_inline_description from '../../components/application-ui/forms/checkboxes/list_with_inline_description';
import Checkbox_simple_list_with_heading from '../../components/application-ui/forms/checkboxes/simple_list_with_heading';

function Checkboxes() {
  return (
    <div>
      <Checkbox_list_with_checkbox_on_right/>
      <Checkbox_list_with_description/>
      <Checkbox_list_with_inline_description/>
      <Checkbox_simple_list_with_heading/>
    </div>
  );
}
export default Checkboxes;
