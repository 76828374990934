import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Order_History_invoice_list from '../../components/ecommerce/components/order-history/invoice_list';
import Order_History_invoice_list_with_quick_actions from '../../components/ecommerce/components/order-history/invoice_list_with_quick_actions';
import Order_History_invoice_panels from '../../components/ecommerce/components/order-history/invoice_panels';
import Order_History_invoice_table from '../../components/ecommerce/components/order-history/invoice_table';

function Order_Histories() {
  return (
    <div>
      <Order_History_invoice_list/>
      <Order_History_invoice_list_with_quick_actions/>
      <Order_History_invoice_panels/>
      <Order_History_invoice_table/>
    </div>
  );
}
export default Order_Histories;
