const items = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  // More items...
]

export default function List_Container_card_with_dividers({content}) {
  return (
    <div className="overflow-hidden rounded-md bg-white shadow">
      <ul role="list" className="divide-y divide-gray-200">
        {items.map((item) => (
          <li key={item.id} className="px-6 py-4">
            {content}
          </li>
        ))}
      </ul>
    </div>
  )
}
