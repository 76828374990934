import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function Dashbaord() {
  return (
    <div>
    </div>
  );
}
export default Dashbaord;
