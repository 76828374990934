import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Description_List_left_aligned_in_card from '../../components/application-ui/data-display/description-lists/left_aligned_in_card';
import Description_List_left_aligned_on_dark from '../../components/application-ui/data-display/description-lists/left_aligned_on_dark';
import Description_List_left_aligned_striped from '../../components/application-ui/data-display/description-lists/left_aligned_striped';
import Description_List_left_aligned_with_inline_actions from '../../components/application-ui/data-display/description-lists/left_aligned_with_inline_actions';
import Description_List_left_aligned from '../../components/application-ui/data-display/description-lists/left_aligned';
import Description_List_narrow_with_hidden_labels from '../../components/application-ui/data-display/description-lists/narrow_with_hidden_labels';
import Description_List_two_column from '../../components/application-ui/data-display/description-lists/two_column';

function Description_Lists() {
  return (
    <div>
      <Description_List_left_aligned_in_card/><br/><hr/><hr/>
      <Description_List_left_aligned_on_dark/><br/><hr/><hr/>
      <Description_List_left_aligned_striped/><br/><hr/><hr/>
      <Description_List_left_aligned_with_inline_actions/><br/><hr/><hr/>
      <Description_List_left_aligned/><br/><hr/><hr/>
      <Description_List_narrow_with_hidden_labels/><br/><hr/><hr/>
      <Description_List_two_column/><br/><hr/><hr/>
    </div>
  );
}
export default Description_Lists;
