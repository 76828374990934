import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Divider_with_button from '../../components/application-ui/layout/dividers/with_button';
import Divider_with_icon from '../../components/application-ui/layout/dividers/with_icon';
import Divider_with_label_on_left from '../../components/application-ui/layout/dividers/with_label_on_left';
import Divider_with_label from '../../components/application-ui/layout/dividers/with_label';
import Divider_with_title_and_button from '../../components/application-ui/layout/dividers/with_title_and_button';
import Divider_with_title_on_left from '../../components/application-ui/layout/dividers/with_title_on_left';
import Divider_with_title from '../../components/application-ui/layout/dividers/with_title';
import Divider_with_toolbar from '../../components/application-ui/layout/dividers/with_toolbar';

function Dividers() {
  return (
    <div>
      <Divider_with_button/>
      <Divider_with_icon/>
      <Divider_with_label_on_left/>
      <Divider_with_label/>
      <Divider_with_title_and_button/>
      <Divider_with_title_on_left/>
      <Divider_with_title/>
      <Divider_with_toolbar/>
    </div>
  );
}
export default Dividers;
