import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Badge_flat_pill_with_dot from '../../components/application-ui/elements/badges/flat_pill_with_dot';
import Badge_flat_pill from '../../components/application-ui/elements/badges/flat_pill';
import Badge_flat_with_dot from '../../components/application-ui/elements/badges/flat_with_dot';
import Badge_flat_with_remove_button from '../../components/application-ui/elements/badges/flat_with_remove_button';
import Badge_flat from '../../components/application-ui/elements/badges/flat';
import Badge_pill_with_border_and_dot from '../../components/application-ui/elements/badges/pill_with_border_and_dot';
import Badge_pill_with_border from '../../components/application-ui/elements/badges/pill_with_border';
import Badge_small_flat_pill_with_dot from '../../components/application-ui/elements/badges/small_flat_pill_with_dot';
import Badge_small_flat_pill from '../../components/application-ui/elements/badges/small_flat_pill';
import Badge_small_flat_with_dot from '../../components/application-ui/elements/badges/small_flat_with_dot';
import Badge_small_flat from '../../components/application-ui/elements/badges/small_flat';
import Badge_small_pill_with_border from '../../components/application-ui/elements/badges/small_pill_with_border';
import Badge_small_with_border from '../../components/application-ui/elements/badges/small_with_border';
import Badge_with_border_and_dot_on_dark from '../../components/application-ui/elements/badges/with_border_and_dot_on_dark';
import Badge_with_border_and_dot from '../../components/application-ui/elements/badges/with_border_and_dot';
import Badge_with_border_and_remove_button from '../../components/application-ui/elements/badges/with_border_and_remove_button';
import Badge_with_border_on_dark from '../../components/application-ui/elements/badges/with_border_on_dark';
import Badge_with_border from '../../components/application-ui/elements/badges/with_border';

function Badges() {
  return (
    <div>
      <Badge_flat_pill_with_dot/>
      <Badge_flat_pill/>
      <Badge_flat_with_dot/>
      <Badge_flat_with_remove_button/>
      <Badge_flat/>
      <Badge_pill_with_border_and_dot/>
      <Badge_pill_with_border/>
      <Badge_small_flat_pill_with_dot/>
      <Badge_small_flat_pill/>
      <Badge_small_flat_with_dot/>
      <Badge_small_flat/>
      <Badge_small_pill_with_border/>
      <Badge_small_with_border/>
      <Badge_with_border_and_dot_on_dark/>
      <Badge_with_border_and_dot/>
      <Badge_with_border_and_remove_button/>
      <Badge_with_border_on_dark/>
      <Badge_with_border/>
      
    </div>
  );
}
export default Badges;
