import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Category_Filter_sidebar_filters from '../../components/ecommerce/components/category-filters/sidebar_filters';
import Category_Filter_with_centered_text_and_dropdown_product_filters from '../../components/ecommerce/components/category-filters/with_centered_text_and_dropdown_product_filters';
import Category_Filter_with_dropdown_product_filters from '../../components/ecommerce/components/category-filters/with_dropdown_product_filters';
import Category_Filter_with_expandable_product_filter_panel from '../../components/ecommerce/components/category-filters/with_expandable_product_filter_panel';
import Category_Filter_with_inline_actions_and_expandable_sidebar_filters from '../../components/ecommerce/components/category-filters/with_inline_actions_and_expandable_sidebar_filters';

function Category_Filters() {
  return (
    <div>
        <Category_Filter_sidebar_filters/>
        <Category_Filter_with_centered_text_and_dropdown_product_filters/>
        <Category_Filter_with_dropdown_product_filters/>
        <Category_Filter_with_expandable_product_filter_panel/>
        <Category_Filter_with_inline_actions_and_expandable_sidebar_filters/>
    </div>
  );
}
export default Category_Filters;
