import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Checkout_Form_multi_step from '../../components/ecommerce/components/checkout-forms/multi_step';
import Checkout_Form_single_step_with_order_summary from '../../components/ecommerce/components/checkout-forms/single_step_with_order_summary';
import Checkout_Form_split_with_order_summary from '../../components/ecommerce/components/checkout-forms/split_with_order_summary';
import Checkout_Form_with_mobile_order_summary_overlay from '../../components/ecommerce/components/checkout-forms/with_mobile_order_summary_overlay';
import Checkout_Form_with_order_summary_sidebar from '../../components/ecommerce/components/checkout-forms/with_order_summary_sidebar';

function Checkout_Forms() {
  return (
    <div>
        <Checkout_Form_with_order_summary_sidebar/>
    </div>
  );
}
export default Checkout_Forms;
