const items = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  // More items...
]

export default function List_Container_card_with_dividers__full_width_on_mobile({content}) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {items.map((item) => (
          <li key={item.id} className="px-4 py-4 sm:px-6">
            {content}
          </li>
        ))}
      </ul>
    </div>
  )
}
