import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Table_full_width_with_avatars_on_dark from '../../components/application-ui/lists/tables/full_width_with_avatars_on_dark';
import Table_full_width_with_constrained_content from '../../components/application-ui/lists/tables/full_width_with_constrained_content';
import Table_full_width from '../../components/application-ui/lists/tables/full_width';
import Table_simple_in_card from '../../components/application-ui/lists/tables/simple_in_card';
import Table_simple_on_dark from '../../components/application-ui/lists/tables/simple_on_dark';
import Table_simple from '../../components/application-ui/lists/tables/simple';
import Table_with_avatars_and_multi_line_content from '../../components/application-ui/lists/tables/with_avatars_and_multi_line_content';
import Table_with_border from '../../components/application-ui/lists/tables/with_border';
import Table_with_checkboxes from '../../components/application-ui/lists/tables/with_checkboxes';
import Table_with_condensed_content from '../../components/application-ui/lists/tables/with_condensed_content';
import Table_with_grouped_rows from '../../components/application-ui/lists/tables/with_grouped_rows';
import Table_with_hidden_columns_on_mobile from '../../components/application-ui/lists/tables/with_hidden_columns_on_mobile';
import Table_with_sticky_header from '../../components/application-ui/lists/tables/with_sticky_header';
import Table_with_striped_rows from '../../components/application-ui/lists/tables/with_striped_rows';
import Table_with_summary_rows from '../../components/application-ui/lists/tables/with_summary_rows';
import Table_with_uppercase_headings from '../../components/application-ui/lists/tables/with_uppercase_headings';
import Table_with_vertical_lines from '../../components/application-ui/lists/tables/with_vertical_lines';

function Tables() {
  return (
    <div>
        <Table_full_width_with_avatars_on_dark/>
        <Table_full_width_with_constrained_content/>
        <Table_full_width/>
        <Table_simple_in_card/>
        <Table_simple_on_dark/>
        <Table_simple/>
        <Table_with_avatars_and_multi_line_content/>
        <Table_with_border/>
        <Table_with_checkboxes/>
        <Table_with_condensed_content/>
        <Table_with_grouped_rows/>
        <Table_with_hidden_columns_on_mobile/>
        <Table_with_sticky_header/>
        <Table_with_striped_rows/>
        <Table_with_summary_rows/>
        <Table_with_uppercase_headings/>
        <Table_with_vertical_lines/>
    </div>
  );
}
export default Tables;
