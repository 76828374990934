import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Grid_List_actions_with_shared_borders from '../../components/application-ui/lists/grid-lists/actions_with_shared_borders';
import Grid_List_contact_cards_with_small_portraits from '../../components/application-ui/lists/grid-lists/contact_cards_with_small_portraits';
import Grid_List_contact_cards from '../../components/application-ui/lists/grid-lists/contact_cards';
import Grid_List_horizontal_link_cards from '../../components/application-ui/lists/grid-lists/horizontal_link_cards';
import Grid_List_images_with_details from '../../components/application-ui/lists/grid-lists/images_with_details';
import Grid_List_logos_cards_with_description_list from '../../components/application-ui/lists/grid-lists/logos_cards_with_description_list';
import Grid_List_simple_cards from '../../components/application-ui/lists/grid-lists/simple_cards';

function Grid_Lists() {
  return (
    <div>
      <Grid_List_actions_with_shared_borders/>
      <Grid_List_contact_cards_with_small_portraits/>
      <Grid_List_contact_cards/>
      <Grid_List_horizontal_link_cards/>
      <Grid_List_images_with_details/>
      <Grid_List_logos_cards_with_description_list/>
      <Grid_List_simple_cards/>
    </div>
  );
}
export default Grid_Lists;
