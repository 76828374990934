import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Button_buttons_with_leading_icon from '../../components/application-ui/elements/buttons/buttons_with_leading_icon';
import Button_buttons_with_trailing_icon from '../../components/application-ui/elements/buttons/buttons_with_trailing_icon';
import Button_circular_buttons from '../../components/application-ui/elements/buttons/circular_buttons';
import Button_primary_buttons_on_dark from '../../components/application-ui/elements/buttons/primary_buttons_on_dark';
import Button_primary_buttons from '../../components/application-ui/elements/buttons/primary_buttons';
import Button_rounded_primary_buttons from '../../components/application-ui/elements/buttons/rounded_primary_buttons';
import Button_rounded_secondary_buttons from '../../components/application-ui/elements/buttons/rounded_secondary_buttons';
import Button_secondary_buttons_on_dark from '../../components/application-ui/elements/buttons/secondary_buttons_on_dark';
import Button_secondary_buttons from '../../components/application-ui/elements/buttons/secondary_buttons';
import Button_soft_buttons from '../../components/application-ui/elements/buttons/soft_buttons';

function Buttons() {
  return (
    <div>
      <Button_buttons_with_leading_icon/>
      <Button_buttons_with_trailing_icon/>
      <Button_circular_buttons/>
      <Button_primary_buttons_on_dark/>
      <Button_primary_buttons/>
      <Button_rounded_primary_buttons/>
      <Button_rounded_secondary_buttons/>
      <Button_secondary_buttons_on_dark/>
      <Button_secondary_buttons/>
      <Button_soft_buttons/>
    </div>
  );
}
export default Buttons;
