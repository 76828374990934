import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Button_Group_basic from '../../components/application-ui/elements/button-groups/basic';
import Button_Group_icon_only from '../../components/application-ui/elements/button-groups/icon_only';
import Button_Group_with_checkbox_and_dropdown from '../../components/application-ui/elements/button-groups/with_checkbox_and_dropdown';
import Button_Group_with_dropdown from '../../components/application-ui/elements/button-groups/with_dropdown';
import Button_Group_with_stat from '../../components/application-ui/elements/button-groups/with_stat';

function Button_Groups() {
  return (
    <div>
      <Button_Group_basic/>
      <Button_Group_icon_only/>
      <Button_Group_with_checkbox_and_dropdown/>
      <Button_Group_with_dropdown/>
      <Button_Group_with_stat/>
    </div>
  );
}
export default Button_Groups;
