import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Feed_simple_with_icons from '../../components/application-ui/lists/feeds/simple_with_icons';
import Feed_with_comments from '../../components/application-ui/lists/feeds/with_comments';
import Feed_with_multiple_item_types from '../../components/application-ui/lists/feeds/with_multiple_item_types';

function Feeds() {
  return (
    <div>
      <Feed_simple_with_icons/>
      <Feed_with_comments/>
      <Feed_with_multiple_item_types/>
    </div>
  );
}
export default Feeds;
