import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Textarea_simple from '../../components/application-ui/forms/textareas/simple';
import Textarea_with_avatar_and_actions from '../../components/application-ui/forms/textareas/with_avatar_and_actions';
import Textarea_with_preview_button from '../../components/application-ui/forms/textareas/with_preview_button';
import Textarea_with_title_and_pill_actions from '../../components/application-ui/forms/textareas/with_title_and_pill_actions';
import Textarea_with_underline_and_actions from '../../components/application-ui/forms/textareas/with_underline_and_actions';

function Textareas() {
  return (
    <div>
      <Textarea_simple/>
      <Textarea_with_avatar_and_actions/>
      <Textarea_with_preview_button/>
      <Textarea_with_title_and_pill_actions/>
      <Textarea_with_underline_and_actions/>
    </div>
  );
}
export default Textareas;
