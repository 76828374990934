import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Combobox_simple from '../../components/application-ui/forms/comboboxes/simple';
import Combobox_with_check_on_left from '../../components/application-ui/forms/comboboxes/with_check_on_left';
import Combobox_with_image from '../../components/application-ui/forms/comboboxes/with_image';
import Combobox_with_secondary_text from '../../components/application-ui/forms/comboboxes/with_secondary_text';
import Combobox_with_status_indicator from '../../components/application-ui/forms/comboboxes/with_status_indicator';

function Comboboxes() {
  return (
    <div>
      <Combobox_simple/>
      <Combobox_with_check_on_left/>
      <Combobox_with_image/>
      <Combobox_with_secondary_text/>
      <Combobox_with_status_indicator/>
    </div>
  );
}
export default Comboboxes;
