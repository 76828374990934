import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Breadcrumb_contained from '../../components/application-ui/navigation/breadcrumbs/contained';
import Breadcrumb_full_width_bar from '../../components/application-ui/navigation/breadcrumbs/full_width_bar';
import Breadcrumb_simple_with_chevrons from '../../components/application-ui/navigation/breadcrumbs/simple_with_chevrons';
import Breadcrumb_simple_with_slashes from '../../components/application-ui/navigation/breadcrumbs/simple_with_slashes';

function Breadcrumbs() {
  return (
    <div>
      <Breadcrumb_contained/>
      <Breadcrumb_full_width_bar/>
      <Breadcrumb_simple_with_chevrons/>
      <Breadcrumb_simple_with_slashes/>
    </div>
  );
}
export default Breadcrumbs;
