import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Pagination_card_footer_with_page_buttons from '../../components/application-ui/navigation/pagination/card_footer_with_page_buttons';
import Pagination_centered_page_numbers from '../../components/application-ui/navigation/pagination/centered_page_numbers';
import Pagination_simple_card_footer from '../../components/application-ui/navigation/pagination/simple_card_footer';

function Paginations() {
  return (
    <div>
      <Pagination_card_footer_with_page_buttons/>
      <Pagination_centered_page_numbers/>
      <Pagination_simple_card_footer/>
    </div>
  );
}
export default Paginations;
