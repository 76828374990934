import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Form_Layout_labels_on_left from '../../components/application-ui/forms/form-layouts/labels_on_left';
import Form_Layout_stacked_on_dark from '../../components/application-ui/forms/form-layouts/stacked_on_dark';
import Form_Layout_stacked from '../../components/application-ui/forms/form-layouts/stacked';
import Form_Layout_two_column_with_cards from '../../components/application-ui/forms/form-layouts/two_column_with_cards';
import Form_Layout_two_column from '../../components/application-ui/forms/form-layouts/two_column';

function Form_Layouts() {
  return (
    <div>
      <Form_Layout_labels_on_left/>
      <Form_Layout_stacked_on_dark/>
      <Form_Layout_stacked/>
      <Form_Layout_two_column_with_cards/>
      <Form_Layout_two_column/>
    </div>
  );
}
export default Form_Layouts;
