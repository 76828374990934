import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Tab_bar_with_underline from '../../components/application-ui/navigation/tabs/bar_with_underline';
import Tab_full_width_tabs_with_underline from '../../components/application-ui/navigation/tabs/full_width_tabs_with_underline';
import Tab_simple_on_dark from '../../components/application-ui/navigation/tabs/simple_on_dark';
import Tab_tabs_in_pills_on_gray from '../../components/application-ui/navigation/tabs/tabs_in_pills_on_gray';
import Tab_tabs_in_pills_with_brand_color from '../../components/application-ui/navigation/tabs/tabs_in_pills_with_brand_color';
import Tab_tabs_in_pills from '../../components/application-ui/navigation/tabs/tabs_in_pills';
import Tab_tabs_with_underline_and_badges from '../../components/application-ui/navigation/tabs/tabs_with_underline_and_badges';
import Tab_tabs_with_underline_and_icons from '../../components/application-ui/navigation/tabs/tabs_with_underline_and_icons';
import Tab_tabs_with_underline from '../../components/application-ui/navigation/tabs/tabs_with_underline';

function Tabs() {
  return (
    <div>
      <Tab_bar_with_underline/>
      <Tab_full_width_tabs_with_underline/>
      <Tab_simple_on_dark/>
      <Tab_tabs_in_pills_on_gray/>
      <Tab_tabs_in_pills_with_brand_color/>
      <Tab_tabs_in_pills/>
      <Tab_tabs_with_underline_and_badges/>
      <Tab_tabs_with_underline_and_icons/>
      <Tab_tabs_with_underline/>
    </div>
  );
}
export default Tabs;
