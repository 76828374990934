import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Stats_simple_in_cards from '../../components/application-ui/data-display/stats/simple_in_cards';
import Stats_simple_on_dark from '../../components/application-ui/data-display/stats/simple_on_dark';
import Stats_with_brand_icon from '../../components/application-ui/data-display/stats/with_brand_icon';
import Stats_with_shared_borders from '../../components/application-ui/data-display/stats/with_shared_borders';
import Stats_with_trending from '../../components/application-ui/data-display/stats/with_trending';

function Stats() {
  return (
    <div>
      <Stats_simple_in_cards/><br/><hr/><hr/>
      <Stats_simple_on_dark/><br/><hr/><hr/>
      <Stats_with_brand_icon/><br/><hr/><hr/>
      <Stats_with_shared_borders/><br/><hr/><hr/>
      <Stats_with_trending/><br/><hr/><hr/>
    </div>
  );
}
export default Stats;
