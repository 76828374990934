import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Detail_sidebar_on_dark from './components/application-ui/page-examples/detail-screens/sidebar_on_dark';
import Detail_stacked from './components/application-ui/page-examples/detail-screens/stacked';
import Home_sidebar_on_dark from './components/application-ui/page-examples/home-screens/sidebar_on_dark';
import Home_stacked from './components/application-ui/page-examples/home-screens/stacked';
import Setting_sidebar_on_dark from './components/application-ui/page-examples/settings-screens/sidebar_on_dark';
import Setting_stacked from './components/application-ui/page-examples/settings-screens/stacked';

import Cat_with_image_header_and_detail_product_grid from './components/ecommerce/page-examples/category-pages/with_image_header_and_detail_product_grid';
import Cat_with_large_images_and_filters_sidebar from './components/ecommerce/page-examples/category-pages/with_large_images_and_filters_sidebar';
import Cat_with_product_grid_and_pagination from './components/ecommerce/page-examples/category-pages/with_product_grid_and_pagination';
import Cat_with_text_header_and_image_product_grid from './components/ecommerce/page-examples/category-pages/with_text_header_and_image_product_grid';
import Cat_with_text_header_and_simple_product_grid from './components/ecommerce/page-examples/category-pages/with_text_header_and_simple_product_grid';
import Checkout_multi_step from './components/ecommerce/page-examples/checkout-pages/multi_step';
import Checkout_single_step_with_order_summary from './components/ecommerce/page-examples/checkout-pages/single_step_with_order_summary';
import Checkout_split_with_order_summary from './components/ecommerce/page-examples/checkout-pages/split_with_order_summary';
import Checkout_with_mobile_order_summary_overlay from './components/ecommerce/page-examples/checkout-pages/with_mobile_order_summary_overlay';
import Checkout_with_order_summary_sidebar from './components/ecommerce/page-examples/checkout-pages/with_order_summary_sidebar';
import Order_simple_with_full_order_details from './components/ecommerce/page-examples/order-detail-pages/simple_with_full_order_details';
import Order_with_large_images_and_progress_bars from './components/ecommerce/page-examples/order-detail-pages/with_large_images_and_progress_bars';
import Order_with_progress_bars from './components/ecommerce/page-examples/order-detail-pages/with_progress_bars';
import History_simple from './components/ecommerce/page-examples/order-history-pages/simple';
import History_with_invoice_list from './components/ecommerce/page-examples/order-history-pages/with_invoice_list';
import History_with_invoice_list_and_quick_actions from './components/ecommerce/page-examples/order-history-pages/with_invoice_list_and_quick_actions';
import History_with_invoice_panels from './components/ecommerce/page-examples/order-history-pages/with_invoice_panels';
import History_with_invoice_tables from './components/ecommerce/page-examples/order-history-pages/with_invoice_tables';
import Product_with_expandable_product_details from './components/ecommerce/page-examples/product-pages/with_expandable_product_details';
import Product_with_featured_details from './components/ecommerce/page-examples/product-pages/with_featured_details';
import Product_with_image_grid from './components/ecommerce/page-examples/product-pages/with_image_grid';
import Product_with_related_products from './components/ecommerce/page-examples/product-pages/with_expandable_product_details';
import Product_with_tabs_and_related_products from './components/ecommerce/page-examples/product-pages/with_tabs_and_related_products';
import Cart_simple_with_policy_grid from './components/ecommerce/page-examples/shopping-cart-pages/simple_with_policy_grid';
import Cart_with_policy_grid_and_extended_summary from './components/ecommerce/page-examples/shopping-cart-pages/with_policy_grid_and_extended_summary';
import Cart_with_related_products from './components/ecommerce/page-examples/shopping-cart-pages/with_related_products';
import Storefront_with_dark_nav_and_footer from './components/ecommerce/page-examples/storefront-pages/with_dark_nav_and_footer';
import Storefront_with_image_tiles_and_feature_sections from './components/ecommerce/page-examples/storefront-pages/with_image_tiles_and_feature_sections';
import Storefront_with_offers_and_testimonials from './components/ecommerce/page-examples/storefront-pages/with_offers_and_testimonials';
import Storefront_with_overlapping_image_tiles_and_perks from './components/ecommerce/page-examples/storefront-pages/with_overlapping_image_tiles_and_perks';

import About_dark from './components/marketing/page-examples/about-pages/dark';
import About_with_image_tiles from './components/marketing/page-examples/about-pages/with_image_tiles';
import About_with_timeline_and_stats from './components/marketing/page-examples/about-pages/with_timeline_and_stats';
import Landing_with_background_image_hero_and_pricing_section from './components/marketing/page-examples/landing-pages/with_background_image_hero_and_pricing_section';
import Landing_with_large_screenshot_and_testimonial from './components/marketing/page-examples/landing-pages/with_large_screenshot_and_testimonial';
import Landing_with_mobile_screenshot_and_testimonials_grid from './components/marketing/page-examples/landing-pages/with_mobile_screenshot_and_testimonials_grid';
import Landing_with_screenshots_and_stats from './components/marketing/page-examples/landing-pages/with_screenshots_and_stats';
import Pricing_with_comparison_table from './components/marketing/page-examples/pricing-pages/with_comparison_table';
import Pricing_with_four_tiers from './components/marketing/page-examples/pricing-pages/with_four_tiers';
import Pricing_with_three_tiers_and_testimonials from './components/marketing/page-examples/pricing-pages/with_three_tiers_and_testimonials';

import Top_Courses from './components/top-courses';
import CourseDetails from './components/course-details';
import Demo1 from './demo1';

import Dashbaord from './app/pages/dashboard';
import Tables from './app/pages/tables';
import Card_Headings from './app/pages/card-headings';
import Stats from './app/pages/stats';
import Dropdowns from './app/pages/dropdowns';
import Calendars from './app/pages/calendars';
import Description_Lists from './app/pages/description-list';
import Grid_Lists from './app/pages/grid-lists';
import Stacked_Lists from './app/pages/stacked-lists';
import Feeds from './app/pages/feeds';
import Action_Panels from './app/pages/action-panels';
import Checkboxes from './app/pages/checkboxes';
import Comboboxes from './app/pages/comboboxes';
import Form_Layouts from './app/pages/form-layouts';
import Input_Groups from './app/pages/input-groups';
import Radio_Groups from './app/pages/radio-groups';
import Select_Menus from './app/pages/select-menus';
import SignIn_Forms from './app/pages/signin-forms';
import Textareas from './app/pages/textareas';
import Toggles from './app/pages/toggles';
import Avatars from './app/pages/avatars';
import Badges from './app/pages/badges';
import Button_Groups from './app/pages/button-groups';
import Buttons from './app/pages/buttons';
import Alerts from './app/pages/alerts';
import Empty_States from './app/pages/empty-states';
import Steps from './app/pages/steps';
import Tabs from './app/pages/tabs';
import Breadcrumbs from './app/pages/breadcrumbs';
import Paginations from './app/pages/paginations';
import Page_Headings from './app/pages/page-headings';
import Section_Headings from './app/pages/section-headings';
import Containers from './app/pages/containers';
import Dividers from './app/pages/dividers';
import List_Containers from './app/pages/list-containers';
import Product_Features from './app/pages/product-features';
import Product_Lists from './app/pages/product-lists';
import Product_Overviews from './app/pages/product-overviews';
import Product_Quickviews from './app/pages/product-quickviews';
import Checkout_Forms from './app/pages/checkout-forms';
import Category_Previews from './app/pages/category-previews';
import Category_Filters from './app/pages/category-filters';
import Store_Navigations from './app/pages/store-navigations';
import Incentives from './app/pages/incentives';
import Order_Histories from './app/pages/order-histories';
import Order_Summaries from './app/pages/order-summaries';
import Promo_Sections from './app/pages/promo-sections';
import Reviews from './app/pages/reviews';
import Shopping_Carts from './app/pages/shopping-carts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Demo1 />}>
          <Route index element={<Dashbaord />} />
          <Route path="tables" element={<Tables />} />
          <Route path="card-headings" element={<Card_Headings />} />
          <Route path="stats" element={<Stats />} />
          <Route path="dropdowns" element={<Dropdowns />} />
          <Route path="calendars" element={<Calendars />} />
          <Route path="description-lists" element={<Description_Lists />} />
          <Route path="grid-lists" element={<Grid_Lists />} />
          <Route path="stacked-lists" element={<Stacked_Lists />} />
          <Route path="feeds" element={<Feeds />} />
          <Route path="action-panels" element={<Action_Panels />} />
          <Route path="checkboxes" element={<Checkboxes />} />
          <Route path="comboboxes" element={<Comboboxes />} />
          <Route path="form-layouts" element={<Form_Layouts />} />
          <Route path="input-groups" element={<Input_Groups />} />
          <Route path="radio-groups" element={<Radio_Groups />} />
          <Route path="select-menus" element={<Select_Menus />} />
          <Route path="signin-forms" element={<SignIn_Forms />} />
          <Route path="textareas" element={<Textareas />} />
          <Route path="toggles" element={<Toggles />} />
          <Route path="avatars" element={<Avatars />} />
          <Route path="badges" element={<Badges />} />
          <Route path="button-groups" element={<Button_Groups />} />
          <Route path="buttons" element={<Buttons />} />
          <Route path="alerts" element={<Alerts />} />
          <Route path="empty-states" element={<Empty_States />} />
          <Route path="steps" element={<Steps />} />
          <Route path="tabs" element={<Tabs />} />
          <Route path="breadcrumbs" element={<Breadcrumbs />} />
          <Route path="paginations" element={<Paginations />} />
          <Route path="page-headings" element={<Page_Headings />} />
          <Route path="section-headings" element={<Section_Headings />} />
          <Route path="containers" element={<Containers />} />
          <Route path="dividers" element={<Dividers />} />
          <Route path="list-containers" element={<List_Containers />} />
        </Route>
        <Route path="ecom" element={<Store_Navigations />}>
          <Route path="category-filters" element={<Category_Filters />} />
          <Route path="category-previews" element={<Category_Previews />} />
          <Route path="checkout-forms" element={<Checkout_Forms />} />
          <Route path="incentives" element={<Incentives />} />
          <Route path="order-histories" element={<Order_Histories />} />
          <Route path="order-summaries" element={<Order_Summaries />} />
          <Route path="product-features" element={<Product_Features />} />
          <Route path="product-lists" element={<Product_Lists />} />
          <Route path="product-overviews" element={<Product_Overviews />} />
          <Route path="product-quickviews" element={<Product_Quickviews />} />
          <Route path="promo-sections" element={<Promo_Sections />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="shopping-carts" element={<Shopping_Carts />} />
        </Route>
        <Route path="pages" element={<App />}>
          <Route path="store-navigations" element={<Store_Navigations />} />
          <Route path="top-courses" element={<Top_Courses />} />
          <Route path="course-details" element={<CourseDetails />} />
        
          /* Application-UI */
          <Route path="detail1" element={<Detail_sidebar_on_dark />} />
          <Route path="detail2" element={<Detail_stacked />} />
          
          <Route path="home1" element={<Home_sidebar_on_dark />} />
          <Route path="home2" element={<Home_stacked />} />
          
          <Route path="settings1" element={<Setting_sidebar_on_dark />} />
          <Route path="settings2" element={<Setting_stacked />} />
          
          /* Ecommerce */
          <Route path="category1" element={<Cat_with_image_header_and_detail_product_grid />} />
          <Route path="category2" element={<Cat_with_large_images_and_filters_sidebar />} />
          <Route path="category3" element={<Cat_with_product_grid_and_pagination />} />
          <Route path="category4" element={<Cat_with_text_header_and_image_product_grid />} />
          <Route path="category5" element={<Cat_with_text_header_and_simple_product_grid />} />
          
          <Route path="checkout1" element={<Checkout_multi_step />} />
          <Route path="checkout2" element={<Checkout_single_step_with_order_summary />} />
          <Route path="checkout3" element={<Checkout_split_with_order_summary />} />
          <Route path="checkout4" element={<Checkout_with_mobile_order_summary_overlay />} />
          <Route path="checkout5" element={<Checkout_with_order_summary_sidebar />} />
          
          <Route path="order1" element={<Order_simple_with_full_order_details />} />
          <Route path="order2" element={<Order_with_large_images_and_progress_bars />} />
          <Route path="order3" element={<Order_with_progress_bars />} />
          
          <Route path="history1" element={<History_simple />} />
          <Route path="history2" element={<History_with_invoice_list />} />
          <Route path="history3" element={<History_with_invoice_list_and_quick_actions />} />
          <Route path="history4" element={<History_with_invoice_panels />} />
          <Route path="history5" element={<History_with_invoice_tables />} />

          <Route path="product1" element={<Product_with_expandable_product_details />} />
          <Route path="product2" element={<Product_with_featured_details />} />
          <Route path="product3" element={<Product_with_image_grid />} />
          <Route path="product4" element={<Product_with_related_products />} />
          <Route path="product5" element={<Product_with_tabs_and_related_products />} />

          <Route path="cart1" element={<Cart_simple_with_policy_grid />} />
          <Route path="cart2" element={<Cart_with_policy_grid_and_extended_summary />} />
          <Route path="cart3" element={<Cart_with_related_products />} />

          <Route path="store1" element={<Storefront_with_dark_nav_and_footer />} />
          <Route path="store2" element={<Storefront_with_image_tiles_and_feature_sections />} />
          <Route path="store3" element={<Storefront_with_offers_and_testimonials />} />
          <Route path="store4" element={<Storefront_with_overlapping_image_tiles_and_perks />} />

          /* Marketing */
          <Route path="about1" element={<About_dark />} />
          <Route path="about2" element={<About_with_image_tiles />} />
          <Route path="about3" element={<About_with_timeline_and_stats />} />
          
          <Route path="landing1" element={<Landing_with_background_image_hero_and_pricing_section />} />
          <Route path="landing2" element={<Landing_with_large_screenshot_and_testimonial />} />
          <Route path="landing3" element={<Landing_with_mobile_screenshot_and_testimonials_grid />} />
          <Route path="landing4" element={<Landing_with_screenshots_and_stats />} />

          <Route path="pricing1" element={<Pricing_with_comparison_table />} />
          <Route path="pricing2" element={<Pricing_with_four_tiers />} />
          <Route path="pricing3" element={<Pricing_with_three_tiers_and_testimonials />} />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
