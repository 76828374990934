import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Calendar_borderless_side_by_side from '../../components/application-ui/data-display/calendars/borderless_side_by_side';
import Calendar_borderless_stacked from '../../components/application-ui/data-display/calendars/borderless_stacked';
import Calendar_day_view from '../../components/application-ui/data-display/calendars/day_view';
import Calendar_double from '../../components/application-ui/data-display/calendars/double';
import Calendar_month_view from '../../components/application-ui/data-display/calendars/month_view';
import Calendar_small_with_meetings from '../../components/application-ui/data-display/calendars/small_with_meetings';
import Calendar_week_view from '../../components/application-ui/data-display/calendars/week_view';
import Calendar_year_view from '../../components/application-ui/data-display/calendars/year_view';

function Calendars() {
  return (
    <div>
      <Calendar_borderless_side_by_side/><br/><hr/><hr/>
      <Calendar_borderless_stacked/><br/><hr/><hr/>
      <Calendar_day_view/><br/><hr/><hr/>
      <Calendar_double/><br/><hr/><hr/>
      <Calendar_month_view/><br/><hr/><hr/>
      <Calendar_small_with_meetings/><br/><hr/><hr/>
      <Calendar_week_view/><br/><hr/><hr/>
      <Calendar_year_view/><br/><hr/><hr/>
    </div>
  );
}
export default Calendars;
