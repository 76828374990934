import { Link, Outlet } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Section_Heading_simple from '../../components/application-ui/headings/section-headings/simple';
import Section_Heading_with_action from '../../components/application-ui/headings/section-headings/with_action';
import Section_Heading_with_actions_and_tabs from '../../components/application-ui/headings/section-headings/with_actions_and_tabs';
import Section_Heading_with_actions from '../../components/application-ui/headings/section-headings/with_actions';
import Section_Heading_with_badge_and_dropdown from '../../components/application-ui/headings/section-headings/with_badge_and_dropdown';
import Section_Heading_with_description from '../../components/application-ui/headings/section-headings/with_description';
import Section_Heading_with_inline_tabs from '../../components/application-ui/headings/section-headings/with_inline_tabs';
import Section_Heading_with_input_group from '../../components/application-ui/headings/section-headings/with_input_group';
import Section_Heading_with_label from '../../components/application-ui/headings/section-headings/with_label';
import Section_Heading_with_tabs from '../../components/application-ui/headings/section-headings/with_tabs';



function Section_Headings() {
  return (
    <div>
      <Section_Heading_simple/>
      <Section_Heading_with_action/>
      <Section_Heading_with_actions_and_tabs/>
      <Section_Heading_with_actions/>
      <Section_Heading_with_badge_and_dropdown/>
      <Section_Heading_with_description/>
      <Section_Heading_with_inline_tabs/>
      <Section_Heading_with_input_group/>
      <Section_Heading_with_label/>
      <Section_Heading_with_tabs/>
    </div>
  );
}
export default Section_Headings;
